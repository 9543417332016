export let PatternColor;
(function (PatternColor) {
    PatternColor["GREEN"] = "green";
    PatternColor["PURPLE"] = "purple";
    PatternColor["WHITE"] = "white";
})(PatternColor || (PatternColor = {}));
export let PatternPosition;
(function (PatternPosition) {
    PatternPosition["CENTRE"] = "centre";
    PatternPosition["NORTH"] = "north";
    PatternPosition["NORTH_EAST"] = "north_east";
    PatternPosition["EAST"] = "east";
    PatternPosition["SOUTH_EAST"] = "south_east";
    PatternPosition["SOUTH"] = "south";
    PatternPosition["SOUTH_WEST"] = "south_west";
    PatternPosition["WEST"] = "west";
    PatternPosition["NORTH_WEST"] = "north_west";
})(PatternPosition || (PatternPosition = {}));
